import { useGlobalStore, useCurrentUserStore, useCurrentCustomerStore } from 'vue_features/shared/store/composables'

export default function ({ state }) {
  const { currentUser: currentUserState, currentCustomer: currentCustomerState, ...globalState } = state
  globalState.isContentEmbedded = window && window.top !== window.self

  useGlobalStore().$set(globalState)
  useCurrentUserStore().$set(currentUserState)
  useCurrentCustomerStore().$set(currentCustomerState)

  return {}
}
